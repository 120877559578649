import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *::before, *::after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${({ theme }) => theme.fontSize.base};
    color: ${({ theme }) => theme.colors[100]};
  }
  body {
    width: 100%;
    height: 100vh;
    line-height: 1.5;
    letter-spacing: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    display: block;
    width: 100%;
    height: 100%;
  }

`
