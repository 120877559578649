import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Logo from "./logo"
import SocialIcon from "./socialIcon"

const HeaderWrap = styled.nav`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.width.archive};
  box-shadow: ${({ theme }) => theme.boxShadow[1]};
  a {
    display: flex;
    align-items: center;
  }
`
const LinkWrap = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  li {
    padding: 0 ${({ theme }) => theme.spacing[3]};
    line-height: ${({ theme }) => theme.spacing[5]};
  }
`

const Header = () => {
  return (
    <HeaderWrap>
      <Link to="/">
        <Logo />
      </Link>
      <SocialIcon />
      <LinkWrap>
        <li>
          <Link to="/">Top</Link>
        </li>
        <li>
          <Link to="/recipe">Recipe</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
      </LinkWrap>
    </HeaderWrap>
  )
}

export default Header
