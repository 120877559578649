import React from "react"
import styled from "styled-components"

const LogoStyle = styled.svg`
  width: ${({ theme }) => theme.spacing[5]};
  fill: ${({ theme }) => theme.colors.black};
  &:hover {
    fill: ${({ theme }) => theme.colors.secondary};
  }
`

const Logo = () => {
  return (
    <LogoStyle
      id="レイヤー_2"
      data-name="レイヤー 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 353.6"
    >
      <path d="M36.07 208.35V139a8 8 0 0 1 8.28-8.28 8 8 0 0 1 8.28 8.28v31.8l29.75-36.24c2.16-2.64 4.2-4 7.32-4 4.08 0 7.68 3 7.68 7 0 2.52-.84 4-2.4 5.88l-25.19 29.4 27.11 30.6a7.55 7.55 0 0 1 1.92 5.28 8.2 8.2 0 0 1-8.16 7.92c-3.24 0-5-1.56-7.08-4.08l-30.95-36.73v32.52a8 8 0 0 1-8.28 8.28 8 8 0 0 1-8.28-8.28ZM101.43 173.67c0-24.24 16.08-43.68 43.32-43.68s43.31 19.44 43.31 43.68-16.06 43.68-43.31 43.68-43.32-19.44-43.32-43.68Zm70.07 0c0-16.44-9.72-29.76-26.75-29.76S118 157.23 118 173.67s9.72 29.76 26.76 29.76 26.74-13.32 26.74-29.76ZM296.39 208.35v-68.52a8 8 0 0 1 8.15-8.16h17.64c18.24 0 29.16 8.4 29.16 24.6 0 11.28-7.68 19.68-19.08 22.68 5.28 1.68 7.2 4.44 9.72 8.28l10.8 16a9.61 9.61 0 0 1 1.92 5.52c0 5-4.08 7.92-8.4 7.92-3.72 0-5.52-1.68-7.32-4.32l-14.64-22.08c-2.28-3.48-4.56-5.16-9-5.16h-2.4v23.28a8.28 8.28 0 1 1-16.55 0Zm21.71-37.08c10.56 0 16.68-5.28 16.68-13.44 0-8.52-5.88-12.72-16.08-12.72h-5.76v26.16ZM366.14 208.35V139a8.28 8.28 0 1 1 16.56 0v69.36a8.28 8.28 0 1 1-16.56 0ZM399.1 209v-70.13c0-5.16 3.24-8.16 8.16-8.16 4.2 0 5.88 2.28 8.28 5.4L455.38 187v-48.6a7.34 7.34 0 0 1 7.56-7.68 7.47 7.47 0 0 1 7.8 7.68v70c0 4.8-2.88 8.28-7.44 8.28-3.72 0-5.76-1.56-7.8-4.2l-41-52.08V209a7.68 7.68 0 0 1-15.36 0Z" />
      <path d="M244.46 353.55c-.85 0-1.7.05-2.56.05q-3.45 0-6.83-.31c3.12.13 6.24.22 9.39.26Z" />
      <path d="M250 0C111.93 0 0 79.15 0 176.8c0 94.11 104 171 235.07 176.49 3.12.13 6.24.22 9.39.26 1.84 0 3.69.05 5.54.05 138.08 0 250-79.15 250-176.8S388.08 0 250 0Zm165.23 285.49c-21.18 15-46 26.78-73.65 35.06a307 307 0 0 1-45.41 9.87A333.24 333.24 0 0 1 250 333.6q-4 0-8.08-.1c-1.07 0-2.13 0-3.19-.09a58.07 58.07 0 0 1 3.19-116.06c11.13 0 20.4-3.25 27.47-8.74 10.23-8 15.85-20.6 15.85-34.94 0-24.24-16.08-43.68-43.32-43.68s-43.31 19.44-43.31 43.68c0 14.34 5.62 27 15.85 34.94a75.11 75.11 0 0 0-29.76 118.52q-13.35-2.72-26.26-6.58c-27.68-8.28-52.46-20.08-73.64-35.06C43 256 20 217.35 20 176.8S43 97.65 84.78 68.11c21.18-15 46-26.78 73.64-35.06a327.86 327.86 0 0 1 183.16 0c27.69 8.28 52.47 20.08 73.65 35.06C457 97.65 480 136.25 480 176.8s-23 79.2-64.77 108.69ZM215.14 173.67c0-16.44 9.72-29.76 26.76-29.76s26.76 13.32 26.76 29.76-9.72 29.76-26.76 29.76-26.76-13.32-26.76-29.76Z" />
      <path d="M238.71 333.41c1.06 0 2.13.07 3.19.09-1.07 0-2.13-.03-3.19-.09Z" />
    </LogoStyle>
  )
}

export default Logo
