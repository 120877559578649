export const Theme = {
  width: {
    topBanner:
      "clamp( 1rem, calc(( 100vw - 1500px ) /2), calc(( 100vw - 1500px ) /2))",
    archive:
      "clamp( 1rem, calc(( 100vw - 1280px ) /2), calc(( 100vw - 1280px ) /2))",
    single:
      "clamp( 1rem, calc(( 100vw - 960px ) /2), calc(( 100vw - 960px ) /2))",
  },
  break: {
    tb: "(min-width: 768px)",
  },
  colors: {
    transparent: "transparent",

    primary: "#6F7B63",
    secondary: "#A36025",

    black: "#0C0B10",
    white: "#F6FAF9",

    black300: "#616161",
    white300: "#E3F2FD",

    baundary: {
      primary: "rgba(111,123,99,0.5)",
      white: "rgba(255,255,255,0.8)",
      black: "rgba(0,0,0,0.7)",
    },

    gray: {
      100: "#f7fafc",
      200: "#edf2f7",
      300: "#e2e8f0",
      400: "#cbd5e0",
      500: "#a0aec0",
      600: "#718096",
      700: "#4a5568",
      800: "#2d3748",
      900: "#1a202c",
    },
  },
  fontStyle: {
    xs: "400 0.75rem/1rem 'Noto Sans JP'",
    sm: "400 0.875rem/1.25rem 'Noto Sans JP'",
    base: "400 1rem/1.5rem 'Noto Sans JP'",
    lg: "700 1.125rem/2rem 'Noto Sans JP'",
    xl: "700 1.25rem/2rem 'Noto Sans JP'",
  },
  font: {
    body: "Noto Sans JP",
    head: "Zen Maru Gothic",
    mono: "Josefin Sans",
    display: "Amatic SC",
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "2rem",
    "4xl": "2.5rem",
    "5xl": "4rem",
    "6xl": "8rem",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  lineHeight: {
    xs: "1rem",
    sm: "1.25rem",
  },
  spacing: {
    0: 0,
    1: ".5rem",
    2: "1rem",
    3: "1.5rem",
    4: "2.5rem",
    5: "4rem",
    6: "6.5rem",
  },
  borderRadius: {
    1: ".25rem",
    2: ".5rem",
    3: ".75rem",
  },
  boxShadow: {
    1: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    2: "rgba(50, 50, 100, 0.25) 2px 6px 12px -2px, rgba(0, 0, 0, 0.3) 2px 3px 7px -3px;",
  },
}
