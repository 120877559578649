// fontawesomeのスタイルを先にインポート
import "@fortawesome/fontawesome-svg-core/styles.css"

import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "./src/components/layout"
import { GlobalStyle } from "./src/theme/globalStyles"
import { Theme } from "./src/theme/theme"

export function wrapPageElement({ element, props }) {
  return (
    <>
      <GlobalStyle />
      <Layout {...props}>{element}</Layout>
    </>
  )
}

export function wrapRootElement({ element }) {
  return <ThemeProvider theme={Theme}>{element}</ThemeProvider>
}
