import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const Icons = styled.div`
  a {
    display: flex;
    justify-content: center;
  }
  & svg {
    font-size: ${({ theme }) => theme.fontSize["3xl"]};
    color: ${({ theme }) => theme.colors.black};
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`

const SocialIcon = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          social {
            instagram
          }
        }
      }
    }
  `)
  const { social } = data.site.siteMetadata

  return (
    <Icons>
      <a href={social.instagram}>
        <FontAwesomeIcon icon={faInstagram} />
      </a>
    </Icons>
  )
}

export default SocialIcon
